import { ethers, Contract, JsonRpcProvider } from "ethers";
import abi from "./sale.json";

export async function ReadContract() {
  const contractAddress = "0xFFCF35B34a59d787D3fdaDc45E4064558EDA6f2f";

  //const provider = ethers.getDefaultProvider('https://bsc-dataseed.binance.org/');

  const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/");

  //const provider = new JsonRpcProvider("https://data-seed-prebsc-1-s1.binance.org:8545");

  const contract = new Contract(contractAddress, abi, provider);
  // console.log("CONTRACTA", contract);
  return contract;
}
