import logo from "./logo.png";
import bg from "./bg.jpg";
import about from "./about.jpeg";
import faq from "./faq.jpeg";

//import bg from "../public/bg.jpg";
import abi from "./utils/sale.json";
import tokenAbi from "./utils/token.json";

import "./App.css";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
//98224d4e1aa436d5b8fea301e0ff3c53
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits, parseUnits } from "ethers";
//import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import CountdownTimer from "./Countdown/CountdownTimer";
import { ReadContract } from "./utils/readContract";
import { Allowance } from "./utils/allowance";
import { message } from "antd";
import Spinner from "react-spinkit";

// 1. Get projectId
const projectId = "98224d4e1aa436d5b8fea301e0ff3c53";
//const token = "0x6c206A495611B9D0b4C2b70316738cAeB01a69Cc";
const token = "0x55d398326f99059fF775485246999027B3197955";
//const token = "0xaC8A6B542DEe616a90C06FD0f490412B96618cBB";
//const ca = "0x6c206A495611B9D0b4C2b70316738cAeB01a69Cc"; FIRST
const ca = "0xFFCF35B34a59d787D3fdaDc45E4064558EDA6f2f";
const time = Date.now();
//LAST 0xae287547Cf6E8eF3153a920e5e7744961ad9E384
// 2. Set chains
const mainnet = {
 chainId: 1,
 name: "Ethereum",
 currency: "ETH",
 explorerUrl: "https://etherscan.io",
 rpcUrl: "https://cloudflare-eth.com",
};

const bscTestnet = {
 chainId: 97,
 name: "Binance Smart Chain Testnet",
 currency: "BNB",
 explorerUrl: "https://testnet.bscscan.com",
 rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
};

const bscMainnet = {
 chainId: 56,
 name: "Binance Smart Chain",
 currency: "BNB",
 explorerUrl: "https://bscscan.com",
 rpcUrl: "https://bsc-dataseed1.binance.org",
};

// 3. Create a metadata object
const metadata = {
 name: "18plus",
 description: "The first real adult coin",
 url: "https://ai18plus.com/", // origin must match your domain & subdomain
 icons: ["https://ai18pluspresale.com/static/media/logo.ef1c3a51e5491c297bca.jpg"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
 /*Required*/
 metadata,

 /*Optional*/
 enableEIP6963: true, // true by default
 enableInjected: true, // true by default
 enableCoinbase: true, // true by default
 rpcUrl: "https://bsc-dataseed1.binance.org", // used for the Coinbase SDK
 defaultChainId: 56, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
 ethersConfig,
 chains: [bscMainnet],
 projectId,
 enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function Presale() {
 const [weiRaised, setWeiRaised] = useState("");
 const [cap, setCap] = useState("");
 const [openingTime, setOpeningTime] = useState("");
 const [closingTime, setClosingTime] = useState("");
 const [amount, setAmount] = useState("");
 const [loading, setLoading] = useState(false);
 const [maxBuy, setMaxBuy] = useState("");
 const [minBuy, setMinBuy] = useState("");
 const [perCoin, setPerCoin] = useState("");
 const [hasEnded, setHasEnded] = useState(false);
 const [canClaim, setCanClaim] = useState(false);

 const [contribution, setContribution] = useState("");

 const { address, chainId, isConnected } = useWeb3ModalAccount();
 const { walletProvider } = useWeb3ModalProvider();

 const totalRaised = async () => {
  try {
   const contract = await ReadContract();

   const wei = await contract.weiRaised();
   // console.log("WEI", wei);
   setWeiRaised(formatUnits(wei.toString(), 18));
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  totalRaised();
 }, []);

 const getContribution = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.contributions(account);
   // console.log("WEI", wei);
   setContribution(formatUnits(wei.toString(), 18));
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getContribution(address);
 }, [address]);

 const getHardCap = async () => {
  try {
   const contract = await ReadContract();

   const hcap = await contract.cap();
   console.log("cap", hcap);
   setCap(formatUnits(hcap.toString(), 18));
   //setCap(hcap[0][0]);
  } catch (error) {
   console.log(error);
   // setLoading(false);
  }
 };

 useEffect(() => {
  getHardCap();
 }, []);

 const getPerCoin = async () => {
  try {
   const contract = await ReadContract();

   const hcap = await contract.PerUsdt();
   // console.log("cap", hcap);
   setPerCoin(hcap.toString());
   //setCap(hcap[0][0]);
  } catch (error) {
   console.log(error);
   // setLoading(false);
  }
 };

 useEffect(() => {
  getPerCoin();
 }, []);

 const getMinBuy = async () => {
  try {
   const contract = await ReadContract();

   const hcap = await contract.MIN_CONTRIBUTION();
   // console.log("cap", hcap);
   setMinBuy(formatUnits(hcap.toString(), 18));
   //setCap(hcap[0][0]);
  } catch (error) {
   console.log(error);
   // setLoading(false);
  }
 };

 useEffect(() => {
  getMinBuy();
 }, []);

 const getMaxBuy = async () => {
  try {
   const contract = await ReadContract();

   const hcap = await contract.MAX_CONTRIBUTION();
   // console.log("cap", hcap);
   setMaxBuy(formatUnits(hcap.toString(), 18));
   //setCap(hcap[0][0]);
  } catch (error) {
   console.log(error);
   // setLoading(false);
  }
 };

 useEffect(() => {
  getMaxBuy();
 }, []);

 const getOpeningTime = async () => {
  try {
   const contract = await ReadContract();

   const time = parseInt(await contract.openingTime());
   //  console.log("TIME", time);

   setOpeningTime(time * 1000);
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getOpeningTime();
 }, []);

 //CLOSING TIME
 const getClosingTime = async () => {
  try {
   const contract = await ReadContract();

   const time = parseInt(await contract.closingTime());

   setClosingTime(time * 1000);
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getClosingTime();
 }, []);

 const getHasEnded = async () => {
  try {
   const contract = await ReadContract();

   const time = await contract.hasEnded();
   console.log("ENDED", time);

   setHasEnded(time);
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getHasEnded();
 }, []);

 const canWithdraw = async () => {
  try {
   const contract = await ReadContract();

   const time = await contract.canWithdraw();
   //console.log("ENDED", time);

   setCanClaim(time);
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  canWithdraw();
 }, []);

 function formatNumber(number) {
  return Number(number).toLocaleString("en-US", {
   minimumFractionDigits: 2,
   maximumFractionDigits: 2,
  });
 }

 const EstimatedTokens = (amount) => {
  if (!perCoin || !amount) return 0;

  return amount * perCoin;
 };

 const checkAllowance = async () => {
  if (!isConnected) {
   // Handle not connected case
   return "User not connected";
  }

  try {
   const allowance = await Allowance(token, ca, address);
   if (!Number(allowance) || parseFloat(formatUnits(allowance, 18)) < parseFloat(amount)) {
    approve();
    message.info("Please Approve to spend tokens!");
   } else {
    handleOrderToken();
   }
  } catch (error) {
   console.error(error);
   return "Error checking allowance";
  }
 };

 const handleOrderToken = async () => {
  if (!isConnected) return;
  try {
   // console.log('checkpoint', isTrustWallet)
   let contract;
   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   const signer = await provider.getSigner();
   contract = new Contract(ca, abi, signer);
   const data = await contract.purchaseTokens(address, parseUnits(amount, 18));
   //
   async function Pending() {
    const provider = new BrowserProvider(walletProvider);
    const result = await provider.getTransactionReceipt(data.hash);
    try {
     if (result === null) {
      setTimeout(() => {
       Pending();
      }, 2000);
     } else if (result !== null) {
      await totalRaised();
      await getContribution(address);
      alert("Your token purchase was successful!");
      setLoading(false);
     }
    } catch (error) {
     console.log("TX ERROR", error);
     setLoading(false);
    }
   }

   setTimeout(() => {
    Pending();
   }, 2000);
  } catch (error) {
   console.log("PURCHASE ERROR", error);
   let errorMessage;
   if (error.code === 4001) {
    errorMessage = "The request was rejected!";
   } else {
    errorMessage = error.message.split('"')[1];
   }
   alert(errorMessage);
   setLoading(false);
  }
 };

 const handleClaimToken = async () => {
  if (!isConnected) return;
  try {
   // console.log('checkpoint', isTrustWallet)
   let contract;
   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   const signer = await provider.getSigner();
   contract = new Contract(ca, abi, signer);
   const data = await contract.withdrawTokens({ from: address });
   //
   async function Pending() {
    const provider = new BrowserProvider(walletProvider);
    const result = await provider.getTransactionReceipt(data.hash);
    try {
     if (result === null) {
      setTimeout(() => {
       Pending();
      }, 2000);
     } else if (result !== null) {
      alert("Your tokens were sent to your wallet.");
      setLoading(false);
     }
    } catch (error) {
     //  console.log("TX ERROR", error);
     setLoading(false);
    }
   }

   setTimeout(() => {
    Pending();
   }, 2000);
  } catch (error) {
   //  console.log("PURCHASE ERROR", error);
   let errorMessage;
   if (error.code === 4001) {
    errorMessage = "The request was rejected!";
   } else {
    errorMessage = error.message.split('"')[1];
   }
   alert(errorMessage);
   setLoading(false);
  }
 };

 async function approve() {
  try {
   let signer;
   let contract;

   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   signer = await provider.getSigner();
   contract = new Contract(token, tokenAbi, signer);

   const data = await contract.approve(ca, parseUnits(amount, 18));

   async function PendingApprove() {
    try {
     const provider = new BrowserProvider(walletProvider);
     const result = await provider.getTransactionReceipt(data.hash);
     if (result === null) {
      setTimeout(() => {
       PendingApprove();
      }, 2000);
     } else if (result !== null) {
      handleOrderToken();
     }
    } catch (error) {
     setLoading(false);
    }
   }

   setTimeout(() => {
    PendingApprove();
   }, 2000);
  } catch (error) {
   console.log("APPROVE ERROR", error);
   let errorMessage;
   if (error.code == 4001) {
    errorMessage = "The request was rejected!";
   } else {
    errorMessage = error.message.split('"')[1];
   }
   alert(errorMessage);
   setLoading(false);
  }
 }

 const handleContribute = () => {
  if (!amount) return alert("Invalid amount!");

  checkAllowance(token);
 };

 return (
  <div className="App">
   <div className="container-fluid p-0">
    {/* Navigation Menu */}
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
     <div className="container">
      {/* Logo and Title */}
      <a className="navbar-brand" href="#">
       <img src={logo} alt="Logo" height="50" className="me-2" />
      </a>
      {/* Navbar Toggler for Mobile */}
      <button
       className="navbar-toggler"
       type="button"
       data-bs-toggle="collapse"
       data-bs-target="#navbarNav"
       aria-controls="navbarNav"
       aria-expanded="false"
       aria-label="Toggle navigation"
      >
       <span className="navbar-toggler-icon"></span>
      </button>
      {/* Navbar Items */}
      <div className="collapse navbar-collapse" id="navbarNav">
       <ul className="navbar-nav ms-auto">
        {/* About Menu */}
        <li className="nav-item">
         <a className="nav-link" href="#about">
          About
         </a>
        </li>
        {/* How to Buy Menu */}

        <li className="nav-item">
         <a className="nav-link" href="#FAQ">
          FAQ
         </a>
        </li>
        {/* Connect Wallet Button */}
        <li className="nav-item">
         <w3m-button balance={"hide"} />
        </li>
       </ul>
      </div>
     </div>
    </nav>
   </div>

   {/* Two Column Layout */}
   <section
    style={{
     background: `url(${bg})`,
     backgroundRepeat: "no-repeat",
     marginTop: "-40px",
    }}
    className="py-5"
   >
    <div className="row m-5 ">
     {/* First Column */}
     <div className="col-md-6 d-flex align-items-center justify-content-center">
      <div className="text-center bg-white p-2 rounded">
       {openingTime > time && (
        <>
         <h2 className="display-4" style={{ color: "red", textShadow: "2px 2px 4px #111" }}>
          Starts in:
         </h2>

         <CountdownTimer targetDate={openingTime} />
        </>
       )}

       {time > openingTime && !hasEnded && (
        <>
         <h2 className="display-4" style={{ color: "red", textShadow: "2px 2px 4px #111" }}>
          Sale Ended
         </h2>
         <CountdownTimer targetDate={1731099540 * 1000} />
        </>
       )}
      </div>
     </div>
     {/* Second Column */}
     <div className="col-md-6">
      <div
       className="p-4 border border-3 border-white"
       style={{
        backgroundImage: "linear-gradient(to right, #ff0000, #333)",
        borderRadius: "10px",
       }}
      >
       {/* Two Column Layout */}
       <div className="row">
        {/* First Column */}
        <div className="col-md-6">
         <label>YOU PAY</label>

         <div className="input-group mb-3">
          <span className="input-group-text" id="inputGroupFileAddon1">
           <img
            src="https://cryptologos.cc/logos/tether-usdt-logo.png"
            alt="Image"
            height="20"
            width="20"
           />
          </span>
          <input
           type="number"
           min="0"
           className="form-control text-white"
           placeholder="Enter amount"
           style={{ background: "transparent" }}
           onInput={(e) => setAmount(e.target.value)}
          />
         </div>
        </div>
        {/* Second Column */}
        <div className="col-md-6">
         <label>YOU GET</label>
         <div className="input-group mb-3">
          <span className="input-group-text" id="inputGroupFileAddon2">
           <img src={logo} alt="Image" height="20" width="20" />
          </span>
          <input
           type="text"
           className="form-control text-white"
           style={{ background: "transparent" }}
           value={formatNumber(EstimatedTokens(amount))}
           readOnly
          />
         </div>
        </div>
        <div className="col-md-12">
         {loading && <Spinner name="circle" color="#f2bd48" />}
         {/*time > openingTime &&
          !hasEnded &&
          
            <button
                      className="btn btn-danger"
                      onClick={handleContribute}
                    >
                      Contribute
                    </button>
          */}
        </div>
       </div>

       <p></p>

       <div style={{ width: "100%" }}>
        <div style={{ width: "100%", color: "#f2bd48" }}>
         SALE INFO
         <p style={{ color: "#fff", fontSize: "12px" }}>
          ${formatNumber((+weiRaised + 251000).toString())}{" "}
          <input
           style={{ width: "100%" }}
           type="range"
           max={+cap + 251000}
           value={+weiRaised + 251000}
           readOnly
          />
          ${formatNumber((+cap + 251000).toString())}
         </p>
        </div>
        {/*
                <div style={{ width: "49%", float: "right", color: "#f2bd48" }}>
                  Phase 2
                  <p style={{ color: "#fff", fontSize: "12px" }}>
                    {Number(weiRaised - 58.4).toFixed(2)}{" "}
                    <input
                      style={{ width: "50%" }}
                      type="range"
                      max={cap - 55}
                      value={weiRaised - 58.4}
                      readOnly
                    />
                    {cap - 55} BNB
                  </p>
                </div>
                */}
       </div>
       <p></p>

       <div style={{ color: "#fff", fontSize: "18px" }}></div>

       <div justify="center" style={{ color: "#fff", fontSize: "10px !important" }}>
        <p>
         <b>1 $18plus = ${1 / parseInt(perCoin)}</b>
         <br />
         <b>Min Contribution:</b> ${formatNumber(minBuy)}
        </p>
        {isConnected && (
         <p>
          <b>Your Contribution:</b> ${formatNumber(contribution)}
         </p>
        )}
       </div>
       {isConnected && canClaim && (
        <div className="col-md-12">
         <button className="btn btn-danger" onClick={handleClaimToken}>
          Claim Tokens
         </button>
        </div>
       )}
      </div>

      <div className="row my-5">
       <div className="col-md-12">
        <a href="https://t.me/ai18pluspresale" target="_blank">
         <img src="./tg.png" width="150" />
        </a>
       </div>
      </div>
     </div>
    </div>
   </section>

   <section
    style={{
     background: `url(${about})`,
     backgroundRepeat: "no-repeat",
     marginTop: "-40px",
     backgroundAttachment: "fixed",
     backgroundSize: "cover",
    }}
    className="py-5"
    id="about"
   >
    <div className="container">
     <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8" style={{ fontWeight: "bold" }}>
       <h2 className="display-3" style={{ color: "white", textShadow: "2px 2px 4px red" }}>
        ABOUT US
       </h2>
       <p>Καλώς ήρθατε στην πρώτη “επαφή” του 18+ coin με το οικοσύστημα των κρυπτονομισμάτων.</p>
       <p>
        Για να βρίσκεστε σε αυτήν την σελίδα του private sale, σημαίνει ότι έχετε επιλεγεί ως
        σπουδαστές ή καθηγητές του Ινστιτούτου Ψηφιακης Οικονομίας, αφού από την αρχή του σχεδιασμού
        του project ήταν απαρέγκλιτη θέληση μας να είστε οι ΠΡΩΤΟΙ που θα εμπλακείτε είτε επενδυτικά
        είτε ως συνδημιουργοί για την καλύτερη πορεία του και το κοινό όφελος.
       </p>
       <p>
        Δεν θα γραφτούν λεπτομέρειες σε αυτήν την σελίδα, αφού ΟΛΟΙ και ΟΛΕΣ είσαστε επαρκώς
        ενημερωμένοι, τόσο για την προοπτική του project, όσο και για τον λόγο που σε πλαίσια
        αμοιβαίας εμπιστοσύνης και εκτίμησης βρισκόμαστε ΜΑΖΙ σε αυτά τα πρώτα βήματα του 18+ coin.
       </p>

       <p>Ας απολαύσουμε το “ταξίδι”...</p>
      </div>
      <div className="col-md-2"></div>
     </div>
    </div>
   </section>

   <section
    style={{
     background: `url(${faq})`,
     backgroundRepeat: "no-repeat",
     marginTop: "-40px",
     backgroundAttachment: "scroll",
     backgroundSize: "cover",
    }}
    className="py-5"
    id="about"
   >
    <div className="container">
     <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8" style={{ fontWeight: "bold" }}>
       <h2 className="display-3" style={{ color: "white", textShadow: "2px 2px 4px red" }}>
        FAQ
       </h2>
       {/* FAQ Section */}
       <div className="accordion" id="faqAccordion" style={{ background: "transparent" }}>
        {/* FAQ Item 1 */}
        <div className="accordion-item">
         <h2 className="accordion-header" id="faqHeading1">
          <button
           className="accordion-button"
           type="button"
           data-bs-toggle="collapse"
           data-bs-target="#faqCollapse1"
           aria-expanded="true"
           aria-controls="faqCollapse1"
          >
           Πως μπορώ να αγοράσω;
          </button>
         </h2>
         <div
          id="faqCollapse1"
          className="accordion-collapse collapse show"
          aria-labelledby="faqHeading1"
          data-bs-parent="#faqAccordion"
         >
          <div className="accordion-body">
           Κλικ στο Connect Wallet Σιγουρευομαστε οτι ειμαστε στο BSC Σιγουρευομαστε οτι εχουμε bnb
           για gas fees Αγοραζουμε με Tether Χρησιμοποιουμε Metamask
          </div>
         </div>
        </div>
        {/* FAQ Item 2 */}
        <div className="accordion-item">
         <h2 className="accordion-header" id="faqHeading2">
          <button
           className="accordion-button collapsed"
           type="button"
           data-bs-toggle="collapse"
           data-bs-target="#faqCollapse2"
           aria-expanded="false"
           aria-controls="faqCollapse2"
          >
           Υπάρχει κάποιος οδηγός για να δημιουργήσω metamask και να βάλω το δίκτο Binance Smart
           Chain;
          </button>
         </h2>
         <div
          id="faqCollapse2"
          className="accordion-collapse collapse"
          aria-labelledby="faqHeading2"
          data-bs-parent="#faqAccordion"
         >
          <div className="accordion-body">
           Ναι υπάρχει ένας αναλυτικός οδηγός στο Youtube εδώ
           https://youtu.be/Cr17plgsWaM?si=bUIWd0skju0x5qcQ .
          </div>
         </div>
        </div>
        {/* FAQ Item 3 */}
        <div className="accordion-item">
         <h2 className="accordion-header" id="faqHeading3">
          <button
           className="accordion-button collapsed"
           type="button"
           data-bs-toggle="collapse"
           data-bs-target="#faqCollapse3"
           aria-expanded="false"
           aria-controls="faqCollapse3"
          >
           Πόσο είναι το ελάχιστο και το μέγιστο που μπορώ να αγοράσω;
          </button>
         </h2>
         <div
          id="faqCollapse3"
          className="accordion-collapse collapse"
          aria-labelledby="faqHeading3"
          data-bs-parent="#faqAccordion"
         >
          <div className="accordion-body">
           Το ελαχιστο ειναι 10000 tether χωρις περιορισμο στο μεγιστο. Επισης υπαρχει η δυνατοτητα
           για μια φορα αγορα με 50 tether ως test αγορας.
          </div>
         </div>
        </div>
        {/* FAQ Item 4 */}
        <div className="accordion-item">
         <h2 className="accordion-header" id="faqHeading4">
          <button
           className="accordion-button collapsed"
           type="button"
           data-bs-toggle="collapse"
           data-bs-target="#faqCollapse4"
           aria-expanded="false"
           aria-controls="faqCollapse4"
          >
           Ποιο δίκτυο χρησιμοποιεί το 18+ ;
          </button>
         </h2>
         <div
          id="faqCollapse4"
          className="accordion-collapse collapse"
          aria-labelledby="faqHeading4"
          data-bs-parent="#faqAccordion"
         >
          <div className="accordion-body">Προς το παρόν το Binance Smart Chain .</div>
         </div>
        </div>
        {/* FAQ Item 5 */}
        <div className="accordion-item">
         <h2 className="accordion-header" id="faqHeading5">
          <button
           className="accordion-button collapsed"
           type="button"
           data-bs-toggle="collapse"
           data-bs-target="#faqCollapse5"
           aria-expanded="false"
           aria-controls="faqCollapse5"
          >
           Υπάρχει live support;
          </button>
         </h2>
         <div
          id="faqCollapse5"
          className="accordion-collapse collapse"
          aria-labelledby="faqHeading5"
          data-bs-parent="#faqAccordion"
         >
          <div className="accordion-body">Βέβαια στο telegram εδώ https://t.me/ai18pluspresale</div>
         </div>
        </div>
        {/* Add more FAQ items as needed */}
       </div>
      </div>
      <div className="col-md-2"></div>
     </div>
    </div>
   </section>
  </div>
 );
}

export default Presale;
